$(document).ready(function(e) {

    $("#siteLogo img").attr("src","//cdn.agilitycms.com/scotiabank-chile/scotiabank-azul/assets/img/logo-scotiabank-azul.svg");

    $('.down-menu').click(function() {
        $( ".c-NavMobile" ).slideToggle( "slow", function() {
          // Animation complete.
        });
    });
    

    // TABS
    
    var linksParents = $('.c-TabsMenu__header');
    var links = $('.c-TabsMenu__header a');
    var items = $('.c-TabsMenu_panels');
    links.eq(0).add(items.eq(0)).addClass('active');
    linksParents.on('click', 'a', function(){
        var t = $(this);
        var i = t.index();
        t.add(items.eq(i))
        .addClass('active')
        .siblings().removeClass('active');
        
    });

    // FIN TABS

    $('.owl-carousel').owlCarousel({
        loop:true,
        dots: true,
        margin: 0,
        nav:false,
        navText: ["<",">"],
        items: 1,
        autoplay: true,
        responsive:{
            990:{
                nav: true,
                dots: false
            },
        }
    });

    $('.owl-carousel-2').owlCarousel({
        loop:true,
        dots: true,
        margin: 20,
        nav:false,
        loop: true,
        autoplay: true,
        navText: ["<",">"],
        items: 1,
        responsive:{
            667:{
                items: 2,
                dots: false,
                loop: false
            },

            990:{
                items: 3,
                dots: false
            },
        }
    });

    
});


